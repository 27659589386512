window.onload = function(){
    if(window.location.href.search('/appdown') !== -1){
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
        document.head.innerHTML =
            '<title>error</title><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=0"><link rel="stylesheet" type="text/css" href="https://res.wx.qq.com/open/libs/weui/0.4.1/weui.css">';
        document.body.innerHTML =
            '<div class="weui_msg"><div class="weui_icon_area"><i class="weui_icon_info weui_icon_msg"></i></div><div class="weui_text_area"><h4 class="weui_msg_title">Please open the link in your browser</h4><h4 class="weui_msg_title">请在浏览器中打开此链接</h4></div></div>';
    }
  }
}
