import Vue from 'vue';
import {Button,NavBar,Tab, Tabs,Icon,Swipe, SwipeItem,Lazyload,Popup,PullRefresh,Empty,List,Cell,Overlay,Search,Sticky,Form,Field,Toast,Skeleton,DropdownMenu,DropdownItem,ActionSheet } from 'vant';
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);  
Vue.use(Popup);
Vue.use(PullRefresh);
Vue.use(Empty);
Vue.use(List);
Vue.use(Cell);
Vue.use(Overlay);
Vue.use(Search);
Vue.use(Sticky);
Vue.use(Form);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Skeleton);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(ActionSheet);