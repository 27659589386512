/**
 * 微信相关状态管理
 */
const userModules = {
    state: {
        code: '', // 去换取token的凭证
        openid: '',
    },
    getters: {
        openid: state => state.openid
    },
    mutations: {
        updateOpenid (state, openid) {
            state.openid = openid
        }
    }
}

export default userModules
