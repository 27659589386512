import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: '/wechat/follow'
  // },
  {
    path: '/wechat/follow',
    name: 'Feedback',
    component: () => import('../views/wechat/follow.vue'),
    meta: {
      title: '关注公众号'
    }
  },
  // 回调地址再次中转(配合授权使用的)
  // {
  //   path: '/webapp_follow_test_my',
  //   redirect: '/wechat/follow',
  //   meta: {
  //     title: '关注公众号'
  //   }
  // },
  {
    path: '/feedback',
    component: () => import('../views/feedback/index.vue'),
    meta: {
      title: '反馈历史'
    }
  },
  {
    path: '/feedback/lookDetail',
    name: 'FeedbackDetail',
    component: () => import('../views/feedback/detail.vue'),
    meta: {
      title: '意见反馈'
    }
  },
  {
    path: '/feedback/detail',
    name: 'iterationDetail',
    component: () => import('../views/feedback/iterationDetail.vue'),
    meta: {
      title: '意见反馈'
    }
  },
  {
    path: '/activities/list',
    component: () => import('../views/activities/list.vue'),
    meta: {
      title: '周年庆活动'
    }
  },
  {
    path:'/activities/addGroupCode',
    component:()=> import('../views/activities/addGroupCode'),
    meta:{
      title:'社群码分享'
    }
  },
  {
    path:'/udesk/customer',
    component:()=> import('../views/udesk/customer'),
    meta:{
      title:'客户资料'
    }
  },
  {
    path:'/appdown',
    component:()=> import('../views/app/down'),
    meta:{
      title:'MaKuKuApp'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const { token, userId } = to.query;
  if (token) {
    sessionStorage.setItem('token', token)
  }
  if (userId) {
    sessionStorage.setItem('userId', userId)
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
