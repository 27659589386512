import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import util from '@/lib/util'
import '@/lib/rem'
import 'vant/lib/index.css';
import '@/lib/vant'
import '@/lib/noWechat'
//暂时隐藏接口监听
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

// Sentry.init({
//   Vue,
//   dsn: "http://0aa24128ecd84fbd8b5c6fafb7a50634@110.40.196.149:9000/2",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "my-site-url.com", /^\//],
//     }),
//   ],
//   environment:process.env.NODE_ENV,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

Vue.config.productionTip = false;
Vue.prototype.$util = util;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
